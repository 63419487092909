import { FC } from "react";
import { IonIcon, IonItem, IonList } from "@ionic/react";
import "./ContactDetails.css";
import LinkLabel from "./LinkLabel";

interface ContactDetailsProps {
  data: { icon: any; label: string; value: string }[];
}

const ContactDetails: FC<ContactDetailsProps> = ({ data }) => {
  return (
    <IonList className="contact-details" lines="inset">
      {data.map(({ icon, label, value }, index) => (
        <IonItem key={label}>
          <IonIcon icon={icon} slot="start" className="contact-icon" />
          <LinkLabel label={label} value={value} />
        </IonItem>
      ))}
    </IonList>
  );
};

export default ContactDetails;
