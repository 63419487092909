import React, { FC, ReactNode, useEffect, useState } from "react";
import { IonLabel, IonText } from "@ionic/react";
import "./LinkLabel.css";
import reactStringReplace from "react-string-replace";

interface LinkLabelProps {
  label: string;
  value: string;
}

const LinkLabel: FC<LinkLabelProps> = ({ label, value }) => {
  const [labelToShow, setLabelToShow] = useState<ReactNode | ReactNode[]>(
    <b>{label}</b>
  );
  const [valueToShow, setValueToShow] = useState<ReactNode | ReactNode[]>(
    <>{value}</>
  );

  const hrefRegEx = /((?:(?:https?|ftp):\/\/)?[\w/\-?=%.]+\.[\w/\-&?=%.]+)/g;
  const emailRegEx =
    /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,8}(.[a-zA-Z{2,8}])?)/g;

  const detectLinkLabelTypes = () => {
    if (label === "Address") {
      setLabelToShow(
        <a
          target="_blank"
          href={`https://maps.apple.com/?q=${encodeURI(value)}`}
        >
          {label}
        </a>
      );
      setValueToShow(value);
      return;
    }

    if (emailRegEx.test(label)) {
      setLabelToShow(
        reactStringReplace(label, emailRegEx, (match, i) => (
          <a key={i} target="_blank" href={`mailto:${match}`}>
            {match}
          </a>
        ))
      );
      setValueToShow(value);
    } else if (hrefRegEx.test(label)) {
      setLabelToShow(
        reactStringReplace(label, hrefRegEx, (match, i) => (
          <a key={i} target="_blank" href={match}>
            {new URL(match).host}
          </a>
        ))
      );
      setValueToShow(value);
    }

    if (emailRegEx.test(value)) {
      setValueToShow(
        reactStringReplace(value, emailRegEx, (match, i) => (
          <a key={i} target="_blank" href={`mailto:${match}`}>
            {match}
          </a>
        ))
      );
    } else if (hrefRegEx.test(value)) {
      setValueToShow(
        reactStringReplace(value, hrefRegEx, (match, i) => (
          <a key={i} target="_blank" href={match}>
            {match}
          </a>
        ))
      );
    }
  };

  useEffect(() => {
    detectLinkLabelTypes();
  }, [detectLinkLabelTypes, label, value]);

  return (
    <IonLabel>
      <IonText className="value-section" color="dark">
        <p>{valueToShow}</p>
      </IonText>
      <IonText className="label-section">{labelToShow}</IonText>
    </IonLabel>
  );
};

export default LinkLabel;
